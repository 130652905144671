import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
} from "@mui/material";
import { useAuth } from "../utils/AuthContext";

const TopNavbar = ({ activePage, loggedIn }) => {
  // Navigation items in logged-out state
  const navItemsLoggedOut = [
    { id: "login", label: "Login", path: "/login" },
    { id: "signup", label: "Sign-up", path: "/signup" }
  ];

  // Button functionality for logged-in state
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async (e) => {
    e.preventDefault(); // Prevent default link navigation
    const result = await logout();
    if (result.success) {
      navigate('/login'); // Redirect after successful logout
    }
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#3f51b5", boxShadow: 2 }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#fff" }}>
          PER Live Data Display
        </Typography>
        {
          loggedIn
            ? (
              <Button
              key="logout"
              component={Link}
              to="/login"
              color="inherit"
              onClick={handleLogout}
              sx={{ 
                color: "#fff", 
                textDecoration: "none",
                borderBottom: "none",
                borderRadius: 0,
                mx: 1,
                fontWeight: "normal",
                '&:hover': {
                  borderBottom: "2px solid #fff",
                }
              }}
            >
              Logout
            </Button>
              )
            : navItemsLoggedOut.map((item) => (
                <Button
                  key={item.id}
                  component={Link}
                  to={item.path}
                  color="inherit"
                  sx={{ 
                    color: "#fff", 
                    textDecoration: "none",
                    borderBottom: activePage === item.id ? "2px solid #fff" : "none",
                    borderRadius: 0,
                    mx: 1,
                    fontWeight: activePage === item.id ? "bold" : "normal",
                    '&:hover': {
                      borderBottom: "2px solid #fff",
                    }
                  }}
                >
                  {item.label}
                </Button>
              ))
        }
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;