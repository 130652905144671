import Graph from "./pages/Graph";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Faults from "./pages/Faults";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./utils/AuthContext";

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Graph />} />
          <Route path="/faults" element={<Faults />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          {/* Add more routes as needed */}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
