import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  Button,
  Paper,
  TextField,
  Typography,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getWsUrl } from "../utils/WSConfig";
import Navbar from "../components/Navbar";
import TopNavbar from "../components/TopNavbar";
import { useAuth } from "../utils/AuthContext";

function Faults() {
  const socketRef = useRef(null);
  const [faults, setFaults] = useState({});
  const [customFaults, setCustomFaults] = useState([]);
  const [newFault, setNewFault] = useState("");
  const [open, setOpen] = useState(false);
  const { isAuthenticated, loading } = useAuth();  // Protected Routes
  const navigate = useNavigate();

  // Protected Routes check
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, loading, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      socketRef.current = new WebSocket(getWsUrl(), [], {
        withCredentials: true,
      });
  
      socketRef.current.addEventListener("open", () => {
        const message = JSON.stringify({ jwt: "jwt" });
        socketRef.current.send(message);
      });
  
      socketRef.current.addEventListener("message", (event) => {
        try {
          const data = JSON.parse(event.data);
          const key = data.key;
          if (
            /fault/i.test(key) ||
            customFaults.some((fault) => key.includes(fault))
          ) {
            const timestamp = data.x;
  
            setFaults((prevFaults) => ({
              ...prevFaults,
              [key]: {
                key: key, // Key
                value: data[key], // The actual fault value
                timestamp: timestamp, // The timing data
              },
            }));
          }
        } catch (error) {
          console.error("Error parsing message: ", error);
        }
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [isAuthenticated, customFaults]);

  // Protected Routes check
  if (!isAuthenticated) {
    return null;
  }

  const handleAddFault = () => {
    if (newFault.trim() !== "") {
      setCustomFaults([...customFaults, newFault.trim()]);
      setNewFault("");
    }
  };
  return (
    <Box sx={{ 
      bgcolor: "#1c1f2e",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: "100%",
      overflowX: "hidden", 
    }}>
    <TopNavbar activePage="faults" loggedIn={true}/>

    <Box sx={{ display: "flex", bgcolor: "#1c1f2e", minHeight: "100vh" }}>
      <Navbar />

      <Box sx={{ flexGrow: 1, padding: "40px" }}>
        <Typography
          sx={{ fontSize: "24px", color: "#fff", marginBottom: "20px" }}
        >
          Faults
        </Typography>

        {Object.keys(faults).length > 0 ? (
          Object.entries(faults).map(([key, fault]) => (
            <Paper
              key={key}
              sx={{
                padding: "16px",
                marginBottom: "10px",
                bgcolor: "#333",
                color: "white",
              }}
            >
              <Typography>
                <strong>Timestamp:</strong> {fault.timestamp}
              </Typography>
              <Typography>
                <strong>Key:</strong> {fault.key}
              </Typography>
              <Typography>
                <strong>Value:</strong> {fault.value}
              </Typography>
            </Paper>
          ))
        ) : (
          <Typography sx={{ color: "white", opacity: 0.5 }}>
            No faults to display
          </Typography>
        )}
      </Box>

      <Drawer
        sx={{
          width: "250px",
          "& .MuiDrawer-paper": {
            width: "250px",
            bgcolor: "#191c29",
            boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
            position: "relative",
            height: "100%"
          },
        }}
        variant="permanent"
        anchor="right"
      >
        <Typography sx={{ color: "#fff", padding: "16px" }}>
          Custom Faults
        </Typography>
        <TextField
          label="New Fault"
          value={newFault}
          onChange={(e) => setNewFault(e.target.value)}
          sx={{
            margin: 2,
            input: { color: "#fff" },
            label: { color: "#fff" },
            "& .MuiOutlinedInput-root fieldset": { borderColor: "#fff" },
            "& .MuiOutlinedInput-root:hover fieldset": { borderColor: "#fff" },
            "& .MuiOutlinedInput-root.Mui-focused fieldset": {
              borderColor: "#fff",
            },
          }}
        />
        <Button
          onClick={handleAddFault}
          sx={{
            marginX: "16px",
            color: "#fff",
            bgcolor: "#3f51b5",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          Add Fault
        </Button>
        <List sx={{ width: "100%", color: "#fff" }}>
          {customFaults.map((fault, index) => (
            <ListItem key={index}>
              <ListItemText primary={fault} sx={{ color: "#fff" }} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
    </Box>
  );
}

export default Faults;
