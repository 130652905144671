import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Container,
  Paper,
} from "@mui/material";
import TopNavbar from "../components/TopNavbar";
import { useAuth } from "../utils/AuthContext";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    
    const result = await login(username, password);
    
    if (!result.success) {
      setError(result.error);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return (
    <Box sx={{ bgcolor: "#1c1f2e"}}>
      <TopNavbar activePage="login" loggedIn={false}/>

      <Container component="main" maxWidth="xs">
        <Paper
          elevation={6}
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 4,
            bgcolor: "#1c1f2e",
            color: "#fff",
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              InputProps={{
                style: { color: "#fff" },
              }}
              sx={{
                "& .MuiOutlinedInput-root fieldset": { borderColor: "#fff" },
                "& .MuiOutlinedInput-root:hover fieldset": {
                  borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                  borderColor: "#fff",
                },
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              InputProps={{
                style: { color: "#fff" },
              }}
              sx={{
                "& .MuiOutlinedInput-root fieldset": { borderColor: "#fff" },
                "& .MuiOutlinedInput-root:hover fieldset": {
                  borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                  borderColor: "#fff",
                },
              }}
            />
            {error && (
              <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, bgcolor: "#3f51b5", color: "#fff" }}
            >
              Login
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
