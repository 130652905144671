import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { Box, Typography, Slider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DataChart = ({
  v,
  unit,
  data,
  xRange,
  palette,
  windowSize,
  handleDeleteGraph,
}) => {
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(
    Math.min(windowSize, data.length - 1)
  );
  const [isSliding, setIsSliding] = useState(false);
  const [snapshotData, setSnapshotData] = useState([]);
  const [last_time, setLastTime] = useState(0);
  const [latencyHistory, setLatencyHistory] = useState([]);

  const chartRef = useRef(null);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });

  // Update chart dimensions dynamically
  useEffect(() => {
    function updateDimensions() {
      if (chartRef.current) {
        setChartDimensions({
          width: chartRef.current.offsetWidth,
          height: chartRef.current.offsetHeight,
        });
      }
    }

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    const currentTime = Date.now();
    if (last_time !== 0) {
      const newLatency = currentTime - last_time;
      setLatencyHistory((prev) => [...prev.slice(-9), newLatency]);
    }
    setLastTime(currentTime);
  }, [data]);

  const handleSliderChange = useCallback(
    (e, newValue) => {
      setStartIndex(newValue);
      setEndIndex(
        Math.min(
          newValue + windowSize - 1,
          (isSliding ? snapshotData.length : data.length) - 1
        )
      );
      console.log("window size", newValue + windowSize);
      console.log("start", newValue, "end", newValue + windowSize - 1);
    },
    [data, snapshotData, isSliding, windowSize]
  );

  useEffect(() => {
    if (!isSliding) {
      // Recalculate start and end indexes when new data arrives
      const newStartIndex = Math.max(0, data.length - windowSize);
      setStartIndex(newStartIndex);
      setEndIndex(Math.min(data.length - 1, newStartIndex + windowSize - 1));
    }
  }, [data, isSliding, windowSize]);

  const handleSliderStart = () => {
    setIsSliding(true);
    setSnapshotData([...data]); // Take a snapshot of the current data
  };

  const handleSliderEnd = () => {
    setIsSliding(false);
  };

  const xTicks = useMemo(() => {
    if (data.length === 0) return [];

    const visibleData = data.slice(startIndex, endIndex + 1);
    if (visibleData.length < 2) return [visibleData[0]?.x]; // Prevents NaN issues

    const minX = visibleData[0].x;
    const maxX = visibleData[visibleData.length - 1].x;
    const step = (maxX + 0.05 * (maxX - minX) - minX) / 6;

    return Array.from({ length: 6 }, (_, i) => minX + i * step).slice(1, 6);
  }, [startIndex, endIndex, data.length > 0 ? data[0].x : null]);

  const yTicks = useMemo(() => {
    if (data.length === 0) return [];

    const visibleData = data.slice(startIndex, endIndex + 1);
    if (visibleData.length < 2) return [visibleData[0]?.[v]]; // Prevent NaN issues

    const minY = Math.min(...visibleData.map((point) => point[v]));
    const maxY = Math.max(...visibleData.map((point) => point[v]));

    const step = (maxY - minY) / 5; // Creates 5 even tick marks

    return Array.from({ length: 6 }, (_, i) => minY + i * step);
  }, [startIndex, endIndex, data, v]);

  // Calculate the yRange based on all data
  const yRange = useMemo(() => {
    if (data.length === 0) return ["auto", "auto"];
    const allYValues = data.map((point) => point[v]);
    const minY = Math.min(...allYValues);
    const maxY = Math.max(...allYValues);
    return [minY, maxY];
  }, [data, v]);

  // Calculate the average of the last 10 values
  const averageLast10 = useMemo(() => {
    const sourceData = isSliding ? snapshotData : data;
    const last10Data = sourceData.slice(
      Math.max(0, sourceData.length - 10),
      sourceData.length
    );
    if (last10Data.length === 0) return 0;

    const sum = last10Data.reduce((acc, point) => acc + point[v], 0);
    return (sum / last10Data.length).toFixed(3);
  }, [data, snapshotData, isSliding, v]);

  const averageLatency = useMemo(() => {
    if (latencyHistory.length === 0) return 0;
    const sum = latencyHistory.reduce((acc, lat) => acc + lat, 0);
    return (sum / latencyHistory.length).toFixed(0);
  }, [latencyHistory]);

  const extendedXRange = useMemo(() => {
    if (isSliding) return ["dataMin", "dataMax"];
    if (!xRange) {
      // Extract actual dataMin and dataMax from dataset

      const sliceStart = Math.max(0, data.length - windowSize);
      const sliceEnd = Math.min(data.length - 1, sliceStart + windowSize - 1);
      const xValues = data.slice(sliceStart, sliceEnd + 1).map((d) => d.x); // Replace 'x' with your actual x-axis key
      const minX = Math.min(...xValues);
      const maxX = Math.max(...xValues);

      return [minX, maxX + 0.05 * (maxX - minX)]; // Extend maxX by 10%
    }
    return [xRange[0], xRange[1]];
  }, [xRange, isSliding, data, windowSize]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%", // Prevents stretching beyond container
        position: "relative",
        backgroundColor: "#232940",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        color: "#fff",
        borderRadius: "10px",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: 500,
        padding: 2,
      }}
    >
      <IconButton
        onClick={() => handleDeleteGraph({ v })}
        sx={{
          position: "absolute",
          top: 10, // Adjusted to ensure proper spacing
          left: 10, // Aligns to the top-right corner
          color: "#fff",
          height: 40,
          width: 40,
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.2)", // Lighter hover effect
          },
        }}
      >
        <CloseIcon sx={{ fontSize: 36 }} />
      </IconButton>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{ color: "#eeeee", fontSize: 20, marginBottom: 2 }}
        >
          {v} ({unit})
        </Typography>
        <Box sx={{ width: "100%", height: "100%" }}>
          <ResponsiveContainer width="100%" height="80%">
            <LineChart
              data={
                isSliding
                  ? snapshotData.slice(startIndex, endIndex + 1)
                  : data.slice(startIndex, endIndex + 1)
              }
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <defs>
                <linearGradient id="yAxisFadeMask" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" stopColor="rgba(255, 255, 255, 0)" />{" "}
                  {/* Top fade */}
                  <stop offset="20%" stopColor="rgba(255, 255, 255, 1)" />{" "}
                  {/* Fully visible */}
                  <stop offset="80%" stopColor="rgba(255, 255, 255, 1)" />{" "}
                  {/* Fully visible */}
                  <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />{" "}
                  {/* Bottom fade */}
                </linearGradient>

                <mask id="yAxisFade">
                  <rect
                    x="0"
                    y="0"
                    width="100%"
                    height="100%"
                    fill="url(#yAxisFadeMask)"
                  />
                </mask>
              </defs>
              <CartesianGrid stroke="#444" />
              <XAxis
                dataKey="x"
                domain={extendedXRange}
                tickCount={Math.min(windowSize, 5)}
                type="number"
                tickFormatter={(value) => value.toFixed(3)}
                ticks={xTicks}
                tick={{ fontSize: 16, fontWeight: "bold" }}
                tickLine={{ strokeWidth: 2 }}
              />
              <YAxis
                domain={yRange}
                orientation="right"
                tickFormatter={(value) => value.toFixed(3)}
                ticks={yTicks}
                tick={{
                  fontSize: 16,
                  fill: palette,
                  fontWeight: "bold",
                  mask: "url(#yAxisFade)",
                }}
                axisLine={{ stroke: palette, strokeWidth: 2 }}
                tickLine={{ stroke: palette, strokeWidth: 2 }}
              />
              <Tooltip
                contentStyle={{ backgroundColor: "#444", border: "none" }}
                itemStyle={{ color: "#fff" }}
              />
              <Line
                isAnimationActive={false}
                dataKey={v}
                dot={(props) => {
                  const { cx, cy, index, payload } = props;
                  const isLastPoint = payload.x === data[data.length - 1]?.x; // Ensure it's the last data point

                  if (!isLastPoint) return null;

                  return (
                    <>
                      {/* Outer glow effect */}
                      <circle
                        cx={cx}
                        cy={cy}
                        r={10}
                        fill={palette}
                        opacity={0.5}
                        stroke="none"
                      />
                      {/* Main dot */}
                      <circle
                        cx={cx}
                        cy={cy}
                        r={6}
                        fill={palette}
                        stroke="none"
                        strokeWidth={1.5}
                      />
                    </>
                  );
                }}
                stroke={palette}
                strokeWidth={1} // Thicker line
              />
              <ReferenceLine
                y={data[data.length - 1]?.[v]}
                opacity={0.5}
                label={({ viewBox }) => {
                  const { y, width } = viewBox;
                  const yAxisXPosition = width + 20; // Ensures label aligns with Y-axis
                  const labelWidth = 80;
                  const labelHeight = 24;
                  const rectX = yAxisXPosition;
                  const rectY = y - labelHeight / 2;

                  return (
                    <g>
                      {/* Filled Rectangle */}
                      <rect
                        x={rectX}
                        y={rectY}
                        width={labelWidth}
                        height={labelHeight}
                        fill={palette}
                      />
                      {/* Label Text */}
                      <text
                        x={rectX + labelWidth / 2}
                        y={rectY + labelHeight / 2}
                        fill="white"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fontSize={16}
                        fontWeight="bold"
                      >
                        {Math.round(data[data.length - 1]?.[v] * 1000) / 1000}
                      </text>
                    </g>
                  );
                }}
                stroke={palette}
                strokeWidth={2}
                strokeDasharray="3 3"
              />
            </LineChart>
          </ResponsiveContainer>
          <Box sx={{ marginTop: "20px", textAlign: "center", width: "100%" }}>
            {data.length && data.length > windowSize ? (
              <Slider
                min={0}
                max={Math.max(
                  0,
                  (isSliding ? snapshotData.length : data.length) - windowSize
                )}
                step={1}
                value={startIndex}
                onMouseDown={handleSliderStart}
                onTouchStart={handleSliderStart}
                onChange={handleSliderChange}
                onMouseUp={handleSliderEnd}
                onTouchEnd={handleSliderEnd}
                sx={{ width: "90%" }}
              />
            ) : (
              <div></div>
            )}
            <Typography>
              Viewing data from index <b>{startIndex}</b> to <b>{endIndex}</b>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DataChart;
