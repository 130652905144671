import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const GraphItem = ({ graph, handleDeleteGraph, color }) => {
  return (
    <Box
      key={graph.v}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingX: "16px",
        width: "90%",
        "&:hover .delete-button": {
          visibility: "visible",
          opacity: 1,
          transition: "opacity 0.3s ease-in-out",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            width: "15px",
            height: "15px",
            borderRadius: "50%",
            bgcolor: color,
            marginRight: "6px",
          }}
        />
        <Typography
          variant="h6"
          sx={{ padding: "16px", fontSize: "16px", color: "#fff" }}
        >
          {graph.v}
        </Typography>
      </Box>
      <IconButton
        className="delete-button"
        onClick={() => handleDeleteGraph(graph)}
        sx={{
          visibility: "hidden",
          opacity: 0,
          color: "#fff",
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

export default GraphItem;
