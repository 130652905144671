import DataChart from "../components/DataChart";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  Button,
  Modal,
  TextField,
  Typography,
  Main,
} from "@mui/material";
import GraphItem from "../components/GraphItem";
import { getWsUrl } from "../utils/WSConfig";
import Navbar from "../components/Navbar";
import TopNavbar from "../components/TopNavbar";
import { useAuth } from "../utils/AuthContext";

function Graph() {
  const socketRef = useRef(null);
  const [dataMap, setDataMap] = useState({});
  const [graphs, setGraphs] = useState([]);
  const [open, setOpen] = useState(false);
  const [newGraph, setNewGraph] = useState({ v: "", unit: "" });
  const [windowSize, setWindowSize] = useState(1000); // Default window size
  const { isAuthenticated, loading } = useAuth(); // Protected Routes
  const navigate = useNavigate();

  const palette = [
    "#FF6F61", // Soft Red
    "#6B5B95", // Soft Purple
    "#88B04B", // Soft Green
    "#F7CAC9", // Soft Pink
    "#92A8D1", // Soft Blue
    "#955251", // Soft Brown
    "#B565A7", // Soft Violet
    "#009B77", // Soft Teal
    "#DD4124", // Soft Orange
    "#45B8AC", // Soft Aqua
  ];

  // Protected Routes check
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, loading, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      // Create socket connection
      socketRef.current = new WebSocket(getWsUrl(), [], {
        withCredentials: true,
      });

      // Setup event listeners
      socketRef.current.addEventListener("open", (event) => {
        const message = JSON.stringify({
          jwt: "jwt",
        });
        socketRef.current.send(message);
      });

      socketRef.current.addEventListener("message", (event) => {
        const newPackets = JSON.parse(event.data); // Expecting an array now
        console.log("Received data:", newPackets);
        if (!Array.isArray(newPackets)) {
          console.error("Received data is not an array:", newPackets);
          return;
        }

        setDataMap((prevDataMap) => {
          const updatedDataMap = { ...prevDataMap };

          newPackets.forEach((packet) => {
            const key = packet.key;
            const existingData = updatedDataMap[key] || [];
            updatedDataMap[key] = [...existingData, packet];
          });

          return updatedDataMap;
        });
      });
    }
    // Cleanup on unmount
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [isAuthenticated]); // Empty dependency array - only run once on mount

  // Protected Routes check
  if (!isAuthenticated) {
    return null;
  }

  const handleDeleteGraph = (graphToDelete) => {
    setGraphs((prevGraphs) =>
      prevGraphs.filter((graph) => graph.v !== graphToDelete.v),
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddGraph = () => {
    setGraphs((prevGraphs) => [...prevGraphs, newGraph]);
    setNewGraph({ v: "", unit: "" });
    handleClose();
  };

  return (
    <Box
      sx={{
        bgcolor: "#1c1f2e",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        overflowX: "hidden",
      }}
    >
      <TopNavbar activePage="graph" loggedIn={true} />

      <Box sx={{ bgcolor: "#1c1f2e", display: "flex" }}>
        <Navbar />
        <Box sx={{ bgcolor: "#1c1f2e", minHeight: "100vh", flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {graphs.length ? (
              graphs.map((graph) => (
                <Box key={graph.v} sx={{ marginBottom: "40px" }}>
                  <DataChart
                    data={dataMap[graph.v] || []}
                    handleDeleteGraph={handleDeleteGraph}
                    v={graph.v}
                    unit={graph.unit}
                    windowSize={windowSize}
                    palette={palette[graphs.indexOf(graph) % palette.length]}
                  />
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ fontSize: "40px", color: "#fff", opacity: 0.5 }}
                >
                  No Graphs To Show
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Drawer
          sx={{
            width: "300px",
            "& .MuiDrawer-paper": {
              width: "300px",
              bgcolor: "#191c29",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
              position: "relative",
              height: "100%",
              overflowX: "hidden",
            },
          }}
          variant="permanent"
          anchor="right"
        >
          <Button
            onClick={handleOpen}
            sx={{
              marginTop: "40px",
              marginX: "16px",
              color: "#fff",
              bgcolor: "#3f51b5",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            Add Graph
          </Button>
          {graphs.length > 0 && (
            <TextField
              label="Window Size"
              type="number"
              value={windowSize}
              onChange={(e) => setWindowSize(parseInt(e.target.value))}
              sx={{
                margin: 2, // Uses theme spacing (16px)
                input: { color: "#fff" }, // Styles the input text
                label: { color: "#fff" }, // Styles the label text
                "& .MuiOutlinedInput-root fieldset": { borderColor: "#fff" },
                "& .MuiOutlinedInput-root:hover fieldset": {
                  borderColor: "#fff",
                },
                "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                  borderColor: "#fff",
                },
              }}
            />
          )}
          <List sx={{ width: "auto" }}>
            {graphs.map((graph, index) => (
              <GraphItem
                key={graph.v}
                graph={graph}
                handleDeleteGraph={handleDeleteGraph}
                color={palette[index % palette.length]}
              />
            ))}
          </List>
        </Drawer>

        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              sx={{ marginBottom: "10px" }}
              variant="h6"
              component="h2"
            >
              Add New Graph
            </Typography>
            <TextField
              label="Variable"
              fullWidth
              value={newGraph.v}
              onChange={(e) => setNewGraph({ ...newGraph, v: e.target.value })}
              sx={{ marginBottom: "16px" }}
            />
            <TextField
              label="Unit"
              fullWidth
              value={newGraph.unit}
              onChange={(e) =>
                setNewGraph({ ...newGraph, unit: e.target.value })
              }
              sx={{ marginBottom: "16px" }}
            />
            <Button
              onClick={handleAddGraph}
              variant="contained"
              color="primary"
            >
              Add
            </Button>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
}

export default Graph;
