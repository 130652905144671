import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Drawer,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BarChartIcon from "@mui/icons-material/BarChart";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Drawer
      sx={{
        width: "100px",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "100px",
          bgcolor: "#191c29",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
          position: "relative",
          height: "100%",
          overflowX: "hidden"
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box sx={{ padding: 1 }}>
        <Tooltip title="Graphs" placement="right">
          <Box
            sx={{
              padding: 2,
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.2)", // Lighter hover effect
              },
              justifyContent: "center",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              borderRadius: 2,
            }}
          >
            <Link to="/">
              <IconButton>
                <BarChartIcon
                  sx={{ fontSize: 32, color: "white", "&:hover": {} }}
                />
              </IconButton>
            </Link>
          </Box>
        </Tooltip>
      </Box>

      <Box sx={{ padding: 1 }}>
        <Tooltip title="Faults" placement="right">
          <Box
            sx={{
              padding: 2,
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.2)", // Lighter hover effect
              },
              justifyContent: "center",
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              borderRadius: 2,
            }}
          >
            <Link to="/faults">
              <IconButton>
                <ReportProblemIcon sx={{ fontSize: 32, color: "white" }} />
              </IconButton>
            </Link>
          </Box>
        </Tooltip>
      </Box>
    </Drawer>
  );
};

export default Navbar;
